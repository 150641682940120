<template>
  <section v-if="loading">
    Loading...
  </section>

  <section v-else>
    <header class="flex flex-wrap items-center mb-6">
      <h2 class="pr-3 font-semibold text-gray-600">
        Reviews
      </h2>
    </header>

    <div class="rv-default-data-list data-list-container">
      <vs-table
        id="rj-default-table"
        ref="table"
        :data="reviews"
        :max-items="itemsPerPage"
        :no-data-text="noDataText"
        search
        pagination
        @selected="(review) => openEditDialog(review)"
      >
        <template slot="header">
          <div class="flex sm:hidden">
            <vs-button
              type="border"
              icon-pack="feather"
              icon="icon-filter"
              class="mr-4"
              @click="filtering = true"
            />
          </div>

          <div class="hidden sm:flex items-center flex-grow justify-between">
            <div class="flex items-center data-list-btn-container space-x-4">
              <vs-button
                v-if="canSendSurveys"
                @click="sendSurvey"
              >
                Send Survey
              </vs-button>

              <vs-button
                type="border"
                @click="filtering = true"
              >
                Filter
              </vs-button>

              <vs-button
                v-if="hasFilters"
                type="flat"
                class="px-3"
                @click="resetFilters"
              >
                Reset Filters
              </vs-button>
            </div>

            <vs-dropdown
              v-if="reviews.length"
              vs-trigger-click
              class="cursor-pointer mr-4 items-per-page-handler"
            >
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">
                  {{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ reviews.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : reviews.length }} of {{ queriedItems }}
                </span>
                <feather-icon
                  icon="ChevronDownIcon"
                  svg-classes="h-4 w-4"
                />
              </div>

              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage = 10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 20">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 50">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 100">
                  <span>100</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </template>

        <template slot="thead">
          <vs-th>Customer</vs-th>
          <vs-th>Date</vs-th>
          <vs-th>Location</vs-th>
          <vs-th>
            <span class="w-full uppercase text-center">
              Rating
            </span>
          </vs-th>
          <vs-th>Quote</vs-th>
          <vs-th>ID</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr
              v-for="(review, index) in data"
              :key="review.id"
              :data="review"
            >
              <vs-td>
                <div class="flex items-center">
                  <div class="relative flex-shrink-0 mr-5">
                    <img
                      v-if="review.reviewSite.code === 'facebook' && review.location.facebookAccessToken && review.authorId"
                      :src="`https://graph.facebook.com/${review.authorId}/picture?access_token=${review.location.facebookAccessToken}`"
                      :alt="review.author"
                      class="w-10 h-10 rounded-full object-fill"
                      @error="setImgError(index)"
                    >
                    <span
                      v-else-if="review.hasImgError || !review.photoUrl"
                      class="relative h-10 w-10 flex items-center justify-center rounded-full overflow-hidden bg-primary"
                    >
                      <img
                        :src="defaultAvatar"
                        :alt="review.author"
                        class="object-cover rounded-full w-6"
                      >
                    </span>
                    <img
                      v-else
                      :src="review.photoUrl"
                      :alt="review.author"
                      class="w-10 h-10 rounded-full object-fill"
                    >
                    <span class="absolute bottom-0 right-0 -mb-1 -mr-2 border-2 w-6 h-6 border-white rounded-full ">
                      <img
                        v-if="logos[review.reviewSite.code]"
                        :src="logos[review.reviewSite.code]"
                        alt="Review Site"
                      >
                    </span>
                  </div>
                  <span class="name-clamp clamp-2">{{ review.author }}</span>
                </div>
              </vs-td>
              <vs-td class="whitespace-nowrap">
                <p>{{ format(new Date(review.date), 'MMM. d, yyyy') }}</p>
              </vs-td>
              <vs-td class="whitespace-nowrap">
                <p v-if="duplicateReviewSites[review.reviewSiteId].hasDuplicates">
                  Multiple Locations
                </p>
                <p v-else>
                  {{ review.location.name }}
                </p>
              </vs-td>
              <vs-td>
                <div class="flex items-center justify-center">
                  <star-rating
                    border-color="#F3F4F6"
                    :rating="review.rating * 5"
                    :border-width="1"
                    :rounded-corners="true"
                    :star-size="16"
                    :show-rating="false"
                    :read-only="true"
                    :increment="0.5"
                    :padding="2"
                    :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                  />
                </div>
              </vs-td>
              <vs-td>
                <p class="clamp-2">
                  {{ review.text }}
                </p>
              </vs-td>
              <vs-td class="whitespace-nowrap">
                {{ review.id }}
              </vs-td>
              <vs-td class="whitespace-nowrap">
                <div class="flex">
                  <vs-button
                    icon-pack="feather"
                    icon="icon-edit"
                    type="flat"
                    class="inline-block"
                    title="Edit Review"
                    :color="themeColors.rjPurple"
                    @click.stop="openEditDialog(review)"
                  />
                  <vs-button
                    icon-pack="feather"
                    icon="icon-rotate-ccw"
                    type="flat"
                    class="inline-block"
                    title="Respond"
                    :color="themeColors.rjPurple"
                    @click.stop="respond(review)"
                  />
                  <vs-button
                    icon-pack="feather"
                    :icon=" review.appearOnWidget ? 'icon-eye' : 'icon-eye-off'"
                    type="flat"
                    class="inline-block"
                    title="Widget Visibility"
                    :color="themeColors.rjPurple"
                    @click.stop="toggleWidget(review)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>

    <rj-filter-reviews-dialog
      v-if="filtering"
      :active.sync="filtering"
      :applied-filters="filters"
      :duplicate-review-sites="duplicateReviewSites"
      :all-location-review-sites="allLocationReviewSites"
      @apply-filters="applyFilters"
      @reset-filters="resetFilters"
    />

    <rj-edit-review-dialog
      v-if="editing"
      :active.sync="editing"
      :review="selected"
      :review-site-logo="logos[selected.reviewSite.code] || null"
      @saved="saveReview"
    />
  </section>
</template>

<script>
import { format } from 'date-fns';
import StarRating from 'vue-star-rating';
import { bus, httpBuildQuery } from '@/utils';
import Authorizable from '@/mixins/Authorizable';
import { colors as themeColors } from '@/../themeConfig';
import filters from '@/views/company-dashboard/results/filters/reviews';
import RjEditReviewDialog from '@/views/company-dashboard/results/modals/RjEditReviewDialog.vue';
import RjFilterReviewsDialog from '@/views/company-dashboard/results/modals/RjFilterReviewsDialog.vue';

export default {
  name: 'RjReviews',

  components: {
    StarRating,
    RjEditReviewDialog,
    RjFilterReviewsDialog,
  },

  mixins: [Authorizable],

  data() {
    return {
      itemsPerPage: 20,
      isMounted: false,
      themeColors,
      editing: false,
      selected: {},
      filters: {},
      filtering: false,
      logos: {
        bbb: require('@/assets/images/review-sites/BBBRSB.png'),
        facebook: require('@/assets/images/review-sites/FacebookRSB.png'),
        google: require('@/assets/images/review-sites/GoogleRSB.png'),
        truelocal: require('@/assets/images/review-sites/TrueLocalRSB.png'),
        yellowpages: require('@/assets/images/review-sites/YellowPagesRSB.png'),
        yelp: require('@/assets/images/review-sites/YelpRSB.png'),
        yp: require('@/assets/images/review-sites/ypRSB.png'),
      },
      reviews: [],
      loading: true,
      fetching: true,
      hasFilters: false,
      defaultAvatar: require('@/assets/images/pages/default-avatar.png'),
    };
  },

  computed: {
    allLocationReviewSites() {
      const locations = this.$store.getters['locations/locations'];
      return locations.reduce((sites, location) => {
        const { locationReviewSites = [] } = location;
        return [...sites, ...locationReviewSites];
      }, []);
    },
    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },
    company() {
      return this.$store.getters['companies/company'];
    },
    currentPage() {
      return (this.isMounted) ? this.$refs.table.currentx : 0;
    },
    canSendSurveys() {
      return this.auth.user.companyId || (this.auth.user.partnerId && this.company.partnerCanSendSurveys);
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.reviews.length;
    },
    duplicateReviewSites() {
      // build an object like:
      // {
      //   1: {
      //     hasDuplicates: false,
      //     locationIds: [],
      //   },
      //   2: {
      //     hasDuplicates: true,
      //     locationIds: [7, 23],
      //   },
      // }
      // indicating that this company has assigned the
      // same review site profile for review site id 2 to multiple locations (ids 7 and 23)

      /* eslint-disable no-param-reassign */
      const duplicateReviewSites = this.$store.getters['revenuejump/reviewSites'].reduce((sites, site) => {
        sites[site.id] = {
          hasDuplicates: false,
          locationIds: [],
        };
        return sites;
      }, {});
      /* eslint-enable no-param-reassign */

      const facebook = this.$store.getters['revenuejump/reviewSites'].find((site) => site.code === 'facebook');

      const foundLocationReviewSites = {};
      const locations = this.$store.getters['locations/locations'];
      for (const location of locations) {
        const { locationReviewSites = [] } = location;
        for (const lrs of locationReviewSites) {
          const { identifier, profileUrl, reviewSiteId } = lrs;
          if (reviewSiteId === facebook.id) {
            if (foundLocationReviewSites[location.facebookPageId]) {
              duplicateReviewSites[reviewSiteId].hasDuplicates = true;
              duplicateReviewSites[reviewSiteId].locationIds = locations.filter((l) => l.facebookPageId === location.facebookPageId).map((l) => l.id);
            } else {
              foundLocationReviewSites[location.facebookPageId] = true;
            }
          }
          if (identifier) {
            if (foundLocationReviewSites[identifier]) {
              duplicateReviewSites[reviewSiteId].hasDuplicates = true;
              duplicateReviewSites[reviewSiteId].locationIds = this.allLocationReviewSites.filter((lrs) => lrs.identifier === identifier).map((lrs) => lrs.locationId);
            } else {
              foundLocationReviewSites[identifier] = true;
            }
          }
          if (profileUrl) {
            if (foundLocationReviewSites[profileUrl]) {
              duplicateReviewSites[reviewSiteId].hasDuplicates = true;
              duplicateReviewSites[reviewSiteId].locationIds = this.allLocationReviewSites.filter((lrs) => lrs.profileUrl === profileUrl).map((lrs) => lrs.locationId);
            } else {
              foundLocationReviewSites[profileUrl] = true;
            }
          }
        }
      }
      return duplicateReviewSites;
    },
    noDataText() {
      if (this.loading) {
        return 'Loading...';
      }
      if (this.fetching) {
        return 'Searching...';
      }
      return 'No reviews found for current filter.';
    },
  },

  async mounted() {
    this.filters = filters();
    this.isMounted = true;

    await this.fetchData();
    this.loading = false;

    this.fetchReviews();
    this.fetchFunnels();
  },

  methods: {
    format,

    async fetchData() {
      const companyId = this.$store.getters['companies/company'].id;

      const getLocations = this.$store.dispatch('locations/fetchLocations', httpBuildQuery({
        includeSurveyStats: 0,
        filter: JSON.stringify({
          where: { active: true, companyId },
          include: [
            {
              relation: 'locationReviewSites',
              scope: {
                where: {
                  active: true,
                },
              },
            },
          ],
        }),
      }));

      const getUsers = this.$store.dispatch('users/fetchUsers', httpBuildQuery({
        filter: JSON.stringify({
          where: { active: true, companyId },
          include: [
            {
              relation: 'roleMappings',
              scope: { include: ['role'] },
            },
          ],
        }),
      }));

      Promise.all([getLocations, getUsers]);
    },

    async fetchReviews() {
      this.fetching = true;

      const {
        startDate,
        endDate,
        reviewSite,
        rating,
        location,
        appearOnWidget,
        getSharedReviewSites,
      } = this.filters;

      const locationIds = this.$store.getters['locations/locations'].map((l) => l.id);

      const filter = {
        getSharedReviewSites: getSharedReviewSites ? 1 : 0,
        where: {
          locationId: { inq: locationIds },
          active: true,
        },
        include: [
          {
            relation: 'location',
            scope: {
              fields: ['name', 'timezone', 'facebookAccessToken'],
            },
          },
          {
            relation: 'reviewSite',
            scope: {
              fields: ['name', 'code'],
            },
          },
        ],
      };

      if (this.hasFilters) {
        if (startDate && endDate) {
          filter.where.date = {
            between: [startDate.toISOString(), endDate.toISOString()],
          };
        } else if (startDate) {
          filter.where.date = {
            gte: startDate.toISOString(),
          };
        } else if (endDate) {
          filter.where.date = {
            lte: endDate.toISOString(),
          };
        }

        if (location) {
          filter.where.locationId = location;
        }

        if (reviewSite) {
          filter.where.reviewSiteId = reviewSite;
        }

        if (typeof rating === 'number') {
          filter.where.rating = rating;
        }

        if (appearOnWidget === true) {
          filter.where.appearOnWidget = true;
        }
        if (appearOnWidget === false) {
          filter.where.appearOnWidget = false;
        }
      } else {
        filter.order = 'createdAt DESC';
        filter.limit = 100;
      }

      const reviews = await this.$store.dispatch(
        'reviews/getReviews',
        filter,
      );

      reviews.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

      this.reviews = reviews.filter((r) => {
        if (this.filters.getSharedReviewSites) {
          return true;
        }
        // filter reviews from shared review sites
        return !this.duplicateReviewSites[r.reviewSiteId].hasDuplicates;
      }).map((r) => ({
        ...r,
        visibleText: r.visibleText || r.text,
      }));

      this.fetching = false;
    },

    async fetchFunnels() {
      const companyId = this.$store.getters['companies/company'].id;
      await this.$store.dispatch('funnels/fetchFunnels', httpBuildQuery({
        includeSurveyStats: 0,
        filter: JSON.stringify({
          where: {
            companyId,
          },
        }),
      }));
    },

    sendSurvey() {
      this.isLowJump
        ? bus.$emit('upgrade-company')
        : bus.$emit('show-modal', { modal: 'send-survey' });
    },

    openEditDialog(review) {
      const tmpReview = review;
      if (review.reviewSite.code === 'facebook') {
        if (review.hasImgError || !review.authorId) {
          tmpReview.photoUrl = null;
        } else {
          tmpReview.photoUrl = `https://graph.facebook.com/${review.authorId}/picture?access_token=${review.location.facebookAccessToken}`;
        }
      }
      this.editing = true;
      this.selected = tmpReview;
    },

    respond(review) {
      console.log({ review });

      this.$vs.dialog({
        color: 'primary',
        title: 'Alert!',
        text: 'We are working diligently to improve RevenueJump. This feature is coming soon...',
        acceptText: 'Accept',
      });
    },

    async toggleWidget(review) {
      try {
        const payload = {
          id: review.id,
          appearOnWidget: !review.appearOnWidget,
        };

        await this.$store.dispatch('reviews/updateReview', payload);
        const index = this.reviews.findIndex((r) => r.id === review.id);
        if (index < 0) {
          return;
        }
        this.reviews[index].appearOnWidget = !review.appearOnWidget;
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async saveReview(review) {
      try {
        await this.$store.dispatch('reviews/updateReview', review);
        const index = this.reviews.findIndex((r) => r.id === review.id);
        if (index < 0) {
          return;
        }
        const updatedReview = {
          ...this.reviews[index],
          ...review,
        };
        this.$set(this.reviews, index, updatedReview);
        this.$vs.notify({
          title: 'Success',
          text: 'Review updated.',
          color: 'success',
        });
      } catch (error) {
        console.log(error);
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    applyFilters(filters) {
      this.filters = filters;
      this.hasFilters = true;
      this.fetchReviews();
    },

    resetFilters() {
      this.filters = filters();
      this.hasFilters = false;
      this.fetchReviews();
    },

    setImgError(index) {
      const review = { ...this.reviews[index], hasImgError: true };
      this.$set(this.reviews, index, review);
    },
  },
};
</script>
